<template>
    <div class="container" id="LookCompetitionDetail">
        <el-page-header @back="$router.back()" content="查看赛事信息"></el-page-header>
        <div class="LookCompetitionDetail_content">
            <el-descriptions title="赛事信息" :column="1" :labelStyle="{'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'}">
                <el-descriptions-item label="赛季名称">{{editInfo.SeasonName}}</el-descriptions-item>
                <el-descriptions-item label="运动项目">{{editInfo.EventsName || '无'}}</el-descriptions-item>
                <el-descriptions-item label="赛事全称">{{editInfo.LeagueFullName}}</el-descriptions-item>
                <el-descriptions-item label="赛事简称">{{editInfo.LeagueShortName || '无'}}</el-descriptions-item>
                <el-descriptions-item label="比赛形式">{{editInfo.IsOnline ? '线上赛' : '线下赛'}}</el-descriptions-item>
                <el-descriptions-item label="比赛平台url" v-if="editInfo.IsOnline">{{editInfo.LeagueSite || '无'}}</el-descriptions-item>
                <el-descriptions-item label="赛事logo">
                    <img :src="editInfo.LogoUrl" style="height:100px;" v-if="editInfo.LogoUrl"/>
                    <span v-else>无</span>
                </el-descriptions-item>
                <el-descriptions-item label="封面照片">
                    <img :src="editInfo.CoverUrl" style="height:100px;" v-if="editInfo.CoverUrl"/>
                </el-descriptions-item>
                <el-descriptions-item label="特殊状态">
                    <span :style="editInfo.AbnormalStatus > 0 ? 'color:red' : ''">{{['无（正常）','赛事取消','赛事延期','赛事中断'][editInfo.AbnormalStatus]}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="公告标题" v-if="editInfo.AbnormalStatus > 0 && editInfo.BulletinUrl">
                    <span>{{editInfo.BulletinTitle}}</span>
                    <el-link type="primary" :href="editInfo.BulletinUrl" target="_blank">点击查看</el-link>
                </el-descriptions-item>
                <el-descriptions-item label="比赛时间">
                    <span v-if="editInfo.GameTime">{{editInfo.GameTime.BeginTime | dateFormat('yyyy-MM-dd HH:mm:ss')}} ~ {{editInfo.GameTime.EndTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}</span>
                    <span v-else>未定</span>
                </el-descriptions-item>
                <el-descriptions-item label="比赛地点">
                    <div style="width: 100%;max-width: 700px;display:flex;flex-wrap: wrap;">
                        <el-tag
                            v-for="(item,index) in editInfo.GamePlace" :key="index"
                            :closable="false"
                            disable-transitions effect="plain" size="small"
                            style="margin-right:10px;margin-bottom:5px"
                        >
                            {{item.RegionName}}
                        </el-tag>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item :label="unitItem.name" v-for="(unitItem,unitIndex) in units" :key="unitIndex" >
                    <div v-if="unitItem.list.length > 0" style="width: 100%;max-width: 700px;display:flex;flex-wrap: wrap;">
                        <el-tag
                            v-for="(item,index) in unitItem.list" :key="index"
                            :closable="false"
                            disable-transitions effect="plain" size="small"
                            style="margin-right:10px;margin-bottom:5px"
                        >
                            {{item}}
                        </el-tag>
                    </div>
                    <div v-else>无</div>
                </el-descriptions-item>
                <el-descriptions-item label="赛事详情">
                    <Editor :value="editInfo.Comment" v-if="editInfo.Comment"></Editor>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="赛事分组信息"></el-descriptions>
            <el-card shadow="never" v-for="(item,index) in competitions" :key="index" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                <el-descriptions title="" :column="1" :labelStyle="{'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'}">
                    <el-descriptions-item label="比赛项目">
                        {{item.eventsName}}
                    </el-descriptions-item>
                    <el-descriptions-item label="组别">
                        <div style="width: 100%;display:flex;flex-wrap: wrap;">
                            <el-tag
                                v-for="(groupItem,groupIndex) in item.groups" :key="groupIndex"
                                :closable="false"
                                disable-transitions effect="plain" size="small"
                                style="margin-right:10px;margin-bottom:5px"
                            >
                                {{groupItem.name}}（{{getGroupTypeText(groupItem.type).text || ' - '}}{{groupItem.isVip == 1?'，嘉宾':''}}）
                            </el-tag>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
            <el-divider></el-divider>
        </div>
    </div>
</template>
<script>
import {
    GetSubleagueDetail
} from '@/api'
import Editor from '@/components/Editor';
export default {
    components: { 
        Editor
    },
    data(){
        var id = parseInt(this.$route.params.subLeagueId);
        return {
            Id: isNaN(id) ? 0 : id,
            editInfo:{},
            units:[],
            groupTypeList:[{
                type:1,
                text:'个人'
            },{
                type:2,
                text:'团体'
            },{
                type:3,
                text:'双人组合'
            }],
            competitions:[]
        }
    },
    created(){
        GetSubleagueDetail({
            SubLeagueId: this.Id
        }).then(data=>{
            if(data.Code == 0){
                var editInfo = data.EditInfo;
                this.editInfo = editInfo;
                let {
                    Assist,
                    Host,
                    GuideUnit,
                    Operator,
                    Sponsor,
                    Supporter,
                    Undertaker
                } = editInfo.Units;
                let units = [];
                units.push({name:"主办单位",list: Host ? Host.split(' ') : []});
                units.push({name:"指导单位",list: GuideUnit ? GuideUnit.split(' ') : []});
                units.push({name:"支持单位",list: Supporter ? Supporter.split(' ') : []});
                units.push({name:"承办单位",list: Undertaker ? Undertaker.split(' ') : []});
                units.push({name:"运营单位",list: Operator ? Operator.split(' ') : []});
                units.push({name:"协办单位",list: Assist ? Assist.split(' ') : []});
                units.push({name:"赞助单位",list: Sponsor ? Sponsor.split(' ') : []});
                this.units = units;
                let groupList = editInfo.GroupList;
                let competitions = [];
                for(var i=0;i<groupList.length;i++){
                    let obj = groupList[i];
                    let groupObj = {
                        name: obj.GroupName,
                        type: obj.GroupType,
                        isVip: obj.IsHonored
                    };
                    let index = -1;
                    for(var j=0;j<competitions.length;j++){
                        if(competitions[j].eventsName== obj.EventsName){
                            index = j;
                            break;
                        }
                    }
                    if(index > -1){
                        competitions[index].groups.push(groupObj);
                    }else{
                        competitions.push({
                            eventsName: obj.EventsName,
                            groups: [groupObj]
                        })
                    }
                }
                this.competitions = competitions;
            }
        })
    },
    methods: {
        getGroupTypeText(type){
            return this.groupTypeList.filter(n=>n.type==type)[0] || {};
        }
    }
}
</script>
<style>
#LookCompetitionDetail .el-descriptions-item__content{
    width: 100%;
}
#LookCompetitionDetail .el-descriptions-item__content .editor{
    width: 100%;
}
#LookCompetitionDetail .editor .tox-editor-header{
    display: none;
}
#LookCompetitionDetail .el-card .el-card__body{
    padding: 20px 10px 0 0px !important;
}
</style>